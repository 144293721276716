import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => {
  return (
    <Section>
      <StyledContainer>
        <h3>Sobre mim</h3>
        <p>
          Eu me chamo <strong>Felipe Fernandes Thomé</strong> e sou o criador da
          Ouro Estamparia Digital.
        </p>
        <p>
          Sou formado em <strong>Engenharia de Controle e Automação</strong> com
          um belo e um "pouco inútil" mestrado (talvez isso também explique um
          pouco o meu <u>fascínio pelo digital e pela tecnologia</u>).
        </p>
        <p>
          Já trabalhei em outras áreas também: em gráfica quando adolescente, em
          indústria quando estagiário, em start-up do setor imobiliário e até em
          um porto, sim, aquele mesmo que recebe navios, rsrs.
        </p>
        <p>
          Mas há mais de 4 anos me dedico <strong>apaixonadamente</strong> ao
          mundo da fabricação de <u>estampados</u>.
        </p>
        <p>
          Se quiser me conhecer um pouco mais, aí vão algumas das contas em
          redes sociais que possuo (minhas e da Ouroboros):
        </p>
        <ul>
          <li>
            <a
              href="https://www.linkedin.com/in/felipe-thom%C3%A9-95bab635/"
              target="_blank"
              rel="noopener noreferrer"
            >
              linkedin
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/fthome"
              target="_blank"
              rel="noopener noreferrer"
            >
              insta
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/ouro.artwear"
              target="_blank"
              rel="noopener noreferrer"
            >
              insta ouroboros
            </a>
          </li>
        </ul>
      </StyledContainer>
    </Section>
  )
}

export default Features

const StyledContainer = styled(Container)`
  width: 60%;
  max-width: 670px;
  margin: 0px auto;
  padding-top: 10vh;

  text-align: justify;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }

  p {
    line-height: 24px;
  }

  a {
    color: ${props => props.theme.color.accent};
  }

  ul {
    list-style: circle;
  }
`
