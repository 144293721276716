import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => {
  return (
    <Section id="top">
      <StyledContainer>
        <h1>Sobre nós</h1>
        <p>
          Somos apaixonados por <strong>personalização</strong> e criatividade.
          Nossa missão é possibilitar que todos possam também personalizar o que
          quiserem.
        </p>
        <p>
          Por esse sonho e objetivo criamos a <u>Ouro Estamparia Digital</u>. E
          é através dela que oferecemos serviços de personalização de camisetas
          e outros produtos.
        </p>
        <p>
          Para conhecer um pouco mais tudo que nos permeia, vale a pena dar uma
          conferida na nossa história.
        </p>
        <h3>Nossa História</h3>
        <p>
          Há quatro anos lançamos a nossa marca -{" "}
          <a
            href="https://www.ouroboros.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ouroboros
          </a>
          . Através do seu site e de todo seu ambiente virtual criamos um hub de
          artistas independentes e propomos democratizar a arte no Brasil.
        </p>
        <p>
          Deixando de lado toda a nossa audácia, vale dizer que a partir daí o
          caminho passou a ser bem acidentado.
        </p>
        <p>
          Principalmente porque, muito rapidamente, nosso catálogo de estampas
          passou a ter centenas de ilustrações de outras centenas de artistas.
        </p>
        <p>
          E como prometemos o apoio irrestrito a eles, tínhamos que achar uma{" "}
          <u>maneira viável de comercializar</u> todas elas.
        </p>
        <h3>As estamparias tradicionais</h3>
        <p>
          Como era de se esperar, começamos a buscar soluções. E aí vai pesquisa
          na internet, ligações, visitas e tudo isso que você também deve ter
          feito antes de chegar aqui nessa página ;)
        </p>
        <p>Passamos a experimentar o serviço de vários fornecedores:</p>
        <ul>
          <li>
            <strong>grandes</strong> confecções com <u>serigrafia</u> própria;
          </li>
          <li>
            fornecedores <strong>intermediários</strong> de <u>serigrafia</u>;
          </li>
          <li>
            <strong>autônomos</strong> que prestavam o serviço de{" "}
            <u>serigrafia</u>;
          </li>
          <li>
            <strong>autônomos</strong> que prestavam o serviço de{" "}
            <u>sublimação</u>;
          </li>
          <li>e vários outros.</li>
        </ul>
        <p>
          E, para a nossa surpresa, após 6 meses estávamos decididos a fechar as
          portas e acabar com a nossa história.
        </p>
        <p>
          Aí você me pergunta: "Como assim?! Para onde foi toda aquela audácia
          de democratizar a arte?! Vai desistir agora?"
        </p>
        <p>E sim, nós estávamos decididos a desistir. De duas, uma:</p>
        <ul>
          <li>
            Ou tínhamos <u>muuuuuito estoque</u>
            <sup>1</sup> (já que nas serigrafias é sempre preciso fazer um
            pedido mínimo. Nelas é preciso confeccionar as telas de uma
            ilustração e encomendar uma quantidade mínima de camisetas para
            valer a pena :/);
          </li>
          <li>
            Ou <u>recebíamos de volta</u> diversas camisetas sublimadas (já que
            a reclamação recorrente era de que a camiseta ficava "transparente"
            no corpo, ou fazia "bolinha" depois de algumas lavadas ou era muito
            desconfortável!).
          </li>
        </ul>
        <p>Ahhh... Como é duro ouvir isso dos amados clientes...</p>

        <StyledP>
          <sup>1</sup>Quando eu digo <u>muuuuuito</u> é <u>muuuuuito mesmo!</u>{" "}
          Conta comigo: com <strong>500</strong> estampas diferentes mais a
          obrigação de fazer no mínimo <strong>40</strong> unidades de cada,
          precisaríamos ter <strong>20.000</strong> peças no estoque para ter a
          certeza que teríamos como <u>honrar a primeira venda</u>!!!
        </StyledP>
        <StyledP>
          E conforme o tempo passa, tudo piora. É preciso manter a{" "}
          <u>grade sempre completa</u>, ou seja, ter no estoque os tamanhos{" "}
          <strong>P, M, G e GG</strong>, os modelos{" "}
          <strong>baby look e camiseta</strong> e as cores de malha{" "}
          <strong>branco, cinza-mescla e preto</strong> (esse é o mix que
          vendemos na Ouroboros). Isso levaria o estoque a incríveis{" "}
          <strong>36.000</strong> unidades, isso se mantivéssemos apenas 3
          unidades de cada combinação disponível (afinal de contas não tínhamos
          como adivinhar qual seria o próximo pedido :/).
        </StyledP>
        <StyledP>
          Calma que pode piorar ainda mais! E se chegasse um cliente e comprasse{" "}
          <strong>4 camisetas M preta da estampa fogo</strong> (
          <a
            href="https://www.ouroboros.com.br/produto/fogo-20?selectedModel=Camiseta"
            target="_blank"
            rel="noopener noreferrer"
          >
            que é linda por sinal
          </a>{" "}
          e por isso sim, já aconteceu :/)? Como tínhamos apenas 3 de cada
          combinação nosso estoque "furaria" e isso faria com que tivéssemos que
          sair correndo para a serigrafia encomendar mais camisetas -{" "}
          <strong>no mínimo 40 :/</strong>.
        </StyledP>
        <StyledP>
          <u>Resumindo</u>: era preciso ter um container inteiro de estoque se
          quiséssemos cumprir todos os pedidos à risca e rodar uma pequena loja
          com a nossa diversidade de estampas. Impossível!
        </StyledP>

        <h3>A solução é digital!</h3>
        <p>
          O problema era claro: a qualidade da sublimação não era legal e era
          insustentável continuar com a serigrafia, precisávamos de muita
          flexibilidade.
        </p>
        <p>
          Foi nessa época que conhecemos a técnica DTG. Ela permite estampar
          digitalmente o tecido de algodão, uma espécie de "impressora de
          camisetas".
        </p>
        <p>
          Era tudo o que precisávamos (e temos certeza que é o que todos
          precisam - é para aonde o mercado com certeza irá).{" "}
          <u>A flexibilidade do digital com a qualidade do algodão</u>.
        </p>
        <p>Estávamos salvos! Ufa!!!</p>
        <h3>Nasce a Ouro Estamparia Digital</h3>
        <p>
          Foi então que muitos começaram a nos procurar com diversas ideias e
          necessidades, que nunca uma estamparia tradicional conseguiria fazer.
          E assim do apelo público fomos destinados a dar vida ao serviço de
          estamparia digital.
        </p>
        <p>
          Por isso, se você também já está de saco cheio de todas as restrições
          impostas pelas estamparias tradicionais (
          <strong>"no mínimo 40 unidades, sr."</strong>,{" "}
          <strong>
            "consigo fazer 1 unidade sim, mas só se for em poliéster"
          </strong>
          , "blá, blá, blá"... Então você também precisa vir para o digital ;)
        </p>
      </StyledContainer>
    </Section>
  )
}

export default Features

const StyledContainer = styled(Container)`
  width: 60%;
  max-width: 670px;
  margin: 0px auto;
  padding-top: 10vh;

  text-align: justify;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    padding-top: 5vh;
  }

  p {
    line-height: 24px;
  }

  a {
    color: ${props => props.theme.color.accent};
  }

  ul {
    list-style: circle;
  }

  > h3 {
    @media (max-width: ${props => props.theme.screen.md}) {
      text-align: left;
    }
  }
`

const StyledP = styled.p`
  ${props => props.theme.font_size.xxxsmall}

  a {
    color: ${props => props.theme.color.accent};
  }
`
